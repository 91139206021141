import React from "react";
import { formatDate } from "../utility/helpers";

const CampaignPreview = ({ form, tags }) => {
  useEffect(() => {
    const convertOEmbedTags = () => {
      document.querySelectorAll("oembed[url]").forEach((element) => {
        const url = element.getAttribute("url");
        if (url.includes("youtube.com")) {
          const vCode = url.split("?v=")[1];
          const iframe = `
            <div class="">
              <iframe src="https://www.youtube.com/embed/${vCode}?rel=0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen class=""></iframe>
            </div>`;
          element.parentElement.outerHTML = iframe;
        }
      });
    };

    if (form && form.description) convertOEmbedTags();
  }, [form]);

  return (
    <div id="preview">
      <div id="preview-campaign">
        <div className="blog_container">
          <div
            id="sigh-the-petition"
            style={{
              backgroundColor: form.target > form.count ? "#f58b95" : "#9595d2",
            }}
          >
            <div className="row">
              <div className="col-sm-10">
                <div className="address-tothe">
                  <div className="row">
                    <div className="col-sm-3">
                      <a href="#" style={{ pointerEvents: "none" }}>
                        <img
                          loading="lazy"
                          src={
                            typeof form.bannerImageURL === "string" &&
                            form.bannerImageURL.length
                              ? process.env.REACT_APP_BUCKET_URL +
                                form.bannerImageURL
                              : typeof form.bannerImage === "object" &&
                                form.bannerImage !== null
                              ? URL.createObjectURL(form.bannerImage)
                              : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
                          }
                          alt="Tark"
                        />
                      </a>
                    </div>
                    <div className="col-sm-9 urgent-action">
                      <p>
                        <a href="#" style={{ pointerEvents: "none" }}>
                          {form.title}
                        </a>
                      </p>
                      <div className="march-sign">
                        <h4>
                          <a
                            href="#"
                            target="_blank"
                            className="btn btn-pink"
                            style={{ pointerEvents: "none" }}
                          >
                            Sign the petition
                          </a>
                        </h4>
                        <p>{formatDate(form.start)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-2 singnatures"
                style={{
                  margin: "auto",
                }}
              >
                <h2 style={{ margin: "0px" }}>{form.count}</h2>
                <h4 style={{ margin: "0px" }}>Signatures</h4>{" "}
                {form.target && (
                  <h4 style={{ margin: "0px" }}>Target: {form.target}</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="smile-photo">
          <img
            loading="lazy"
            src={
              typeof form.bannerImageURL === "string" &&
              form.bannerImageURL.length
                ? process.env.REACT_APP_BUCKET_URL + form.bannerImageURL
                : typeof form.bannerImage === "object" &&
                  form.bannerImage !== null
                ? URL.createObjectURL(form.bannerImage)
                : "https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png"
            }
            alt="Resource Image"
          />
        </div>
        <div className="every-one">
          <h2>{form.title}</h2>
          <div className="posted-date">
            <i className="fa-solid fa-calendar-days"></i>
            <span>Posted On: {formatDate(form.start)}</span>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: form.description }}
            className="ck-content"
          ></div>
        </div>
        <div
          className="rounded editor-container p-2 pt-4 pb-4 mt-2 mb-2"
          id="campaign"
        >
          <div className="right-blog-detail">
            <div className="share-this-comp">
              <h5>
                <span>Date of post : </span>
                {form.start && formatDate(form.start)}
              </h5>
              <h5>
                <span>Date of closure : </span>
                {form.end && formatDate(form.end)}
              </h5>
              <h5>
                <span>Host :</span>
                {form.host}
              </h5>
              <h5>
                <span>Contact :</span>
                {form.contact}
              </h5>
              <h5>
                <span>Email :</span>
                {form.email}
              </h5>
            </div>
          </div>
        </div>
        <div className="dolor-blog">
          {form.ptags
            ? form.ptags?.map((tag, index) => (
                <div key={index}>
                  <p>
                    <a href="#" style={{ pointerEvents: "none" }}>
                      {tag.name}
                    </a>
                  </p>
                </div>
              ))
            : tags
                .map((tag, index) => {
                  if (form.tag.some((id) => tag.id === id)) {
                    return (
                      <div key={index}>
                        <p>
                          <a href="#" style={{ pointerEvents: "none" }}>
                            {tag.name}
                          </a>
                        </p>
                      </div>
                    );
                  }
                })
                .filter(Boolean)}
        </div>
      </div>
    </div>
  );
};

export default CampaignPreview;
